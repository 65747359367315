import { createAction, handleActions } from 'redux-actions';
import { alphaSortObjectArray } from 'utilities/arrayUtils';
import { isEqual } from 'lodash';

const initialState = {
  standardAnalytics: [],
  analyticLookup: {},
  equipmentBreakdown: undefined,
  isLoadingStandardAnalytics: true,
  isLoadingStandardAnalyticsDetails: {},
  isDeployingAnalytics: {},
  selectedAnalytics: {},
};

const prefix = 'app/analyticsManager/';

export const types = {
  STANDARD_ANALYTICS_REQUEST: prefix + 'STANDARD_ANALYTICS_REQUEST',
  STANDARD_ANALYTICS_RESPONSE: prefix + 'STANDARD_ANALYTICS_RESPONSE',

  LOAD_ALL_ANALYTICS_DETAILS_REQUEST:
    prefix + 'LOAD_ALL_ANALYTICS_DETAILS_REQUEST',
  LOAD_ALL_ANALYTICS_DETAILS_RESPONSE:
    prefix + 'LOAD_ALL_ANALYTICS_DETAILS_RESPONSE',

  ANALYTIC_DETAILS_REQUEST: prefix + 'ANALYTIC_DETAILS_REQUEST',
  ANALYTIC_DETAILS_RESPONSE: prefix + 'ANALYTIC_DETAILS_RESPONSE',

  DEPLOY_ANALYTICS_REQUEST: prefix + 'DEPLOY_ANALYTICS_REQUEST',
  DEPLOY_ANALYTICS_RESPONSE: prefix + 'DEPLOY_ANALYTICS_RESPONSE',
  DEPLOY_ANALYTICS_ERROR: prefix + 'DEPLOY_ANALYTICS_ERROR',

  CLEAR_DEPLOY: prefix + 'CLEAR_DEPLOY',

  SELECT_BUILDINGS: prefix + 'SELECT_BUILDINGS',

  SELECT_ANALYTIC: prefix + 'SELECT_ANALYTIC',
  SELECT_ALL_ANALYTICS: prefix + 'SELECT_ALL_ANALYTICS',

  SELECT_EQUIPMENT: prefix + 'SELECT_EQUIPMENT',
  SELECT_EQUIPMENT_CLASS: prefix + 'SELECT_EQUIPMENT_CLASS',
};

export const actions = {
  standardAnalyticsRequest: createAction(types.STANDARD_ANALYTICS_REQUEST),
  standardAnalyticsResponse: createAction(types.STANDARD_ANALYTICS_RESPONSE),

  loadAllAnalyticDetailsRequest: createAction(
    types.LOAD_ALL_ANALYTICS_DETAILS_REQUEST
  ),

  analyticDetailsRequest: createAction(types.ANALYTIC_DETAILS_REQUEST),
  analyticDetailsResponse: createAction(types.ANALYTIC_DETAILS_RESPONSE),

  deployAnalyticsRequest: createAction(types.DEPLOY_ANALYTICS_REQUEST),
  deployAnalyticsResponse: createAction(types.DEPLOY_ANALYTICS_RESPONSE),
  deployAnalyticsError: createAction(types.DEPLOY_ANALYTICS_ERROR),

  clearDeploy: createAction(types.CLEAR_DEPLOY),

  selectBuildings: createAction(types.SELECT_BUILDINGS),

  selectAnalytic: createAction(types.SELECT_ANALYTIC),
  selectAllAnalytics: createAction(types.SELECT_ALL_ANALYTICS),

  selectEquipment: createAction(types.SELECT_EQUIPMENT),
  selectEquipmentClass: createAction(types.SELECT_EQUIPMENT_CLASS),
};

export const getStandardAnalytics = (siteId, buildingIds) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.standardAnalyticsRequest());
  try {
    const { data } = await api.analyticsManager.getStandardAnalytics(
      siteId,
      buildingIds
    );
    const analytics = alphaSortObjectArray(data.analytics, 'analyticName');

    // Extract the Analytics and Equipment Classes into a dictionary for easy lookups.
    const analyticLookup = {};
    const equipmentClassLookup = {};
    const selectedAnalytics = {};

    for (let analytic of analytics) {
      const analyticId = analytic.analyticId;

      selectedAnalytics[analyticId] = {
        selected: true,
        excludedEquipment: new Set(),
        excludedEquipmentClasses: new Set(),
      };
      analyticLookup[analyticId] = analytic;

      for (let equipmentClass of analytic.equipmentClasses) {
        equipmentClassLookup[equipmentClass.equipmentClassId] =
          equipmentClass.equipmentClassName;
      }
    }

    const selectedBuildings = _getState().analyticsManager.selectedBuildings;

    // If the selected buildingIds have changed, do not accept these results.
    if (!isEqual(buildingIds, selectedBuildings)) {
      return;
    }

    return dispatch(
      actions.standardAnalyticsResponse({
        analytics,
        analyticLookup,
        equipmentClassLookup,
        selectedAnalytics,
      })
    );
  } catch (err) {
    return dispatch(actions.standardAnalyticsResponse(err));
  }
};

export const getStandardAnalyticsDetails = (
  siteId,
  buildingIds,
  standardAnalytics
) => async (dispatch, _getState, { api }) => {
  let loadingState = standardAnalytics.reduce((acc, curr) => {
    acc[curr.analyticId] = 'pending';
    return acc;
  }, {});

  dispatch(actions.loadAllAnalyticDetailsRequest(loadingState));

  do {
    // Only load point availability for 10 analytics at a given time.
    const concurrentLimit = 10;
    loadingState = _getState().analyticsManager
      .isLoadingStandardAnalyticsDetails;

    const loadingCount = Object.values(loadingState).filter(
      x => x === 'loading'
    ).length;

    let triggered = 0;

    for (let analytic of standardAnalytics) {
      // If we are in the process of loading the concurrentLimit breakout.
      if (loadingCount + triggered >= concurrentLimit) {
        break;
      }

      // Otherwise check if this analytic needs to be loaded.
      const notLoaded = loadingState[analytic.analyticId] === 'pending';

      if (notLoaded) {
        getStandardAnalyticDetails(
          dispatch,
          api,
          _getState,
          siteId,
          buildingIds,
          analytic
        );

        triggered += 1;
      }
    }

    // Wait five seconds before attempting to load more.
    await new Promise(x => setTimeout(x, 1000));
  } while (!areAnalyticsDetailsLoaded(_getState));
};

const areAnalyticsDetailsLoaded = _getState => {
  const loadingState = _getState().analyticsManager
    .isLoadingStandardAnalyticsDetails;

  return Object.values(loadingState).every(x =>
    ['loaded', 'failed'].includes(x)
  );
};

const getStandardAnalyticDetails = async (
  dispatch,
  api,
  _getState,
  siteId,
  buildingIds,
  analytic
) => {
  // Indicate that we are now loading this analytic.
  dispatch(
    actions.analyticDetailsRequest({
      analyticId: analytic.analyticId,
    })
  );

  const {
    analyticPointAvailability,
    equipmentClassPointAvailability,
    equipmentBreakdown,
  } = await getAnalyticPointAvailability(
    api,
    _getState,
    siteId,
    buildingIds,
    analytic.analyticId,
    analytic.equipmentClasses.map(x => x.equipmentClassId)
  );

  const deployedTasks = await getDeployedTasks(
    api,
    _getState,
    siteId,
    buildingIds,
    analytic.analyticId
  );

  const selectedBuildings = _getState().analyticsManager.selectedBuildings;
  const loadingState = _getState().analyticsManager
    .isLoadingStandardAnalyticsDetails;

  // If the selected buildingIds have changed, do not accept these results.
  // If we are not expecting this (it is not currently pending) also discard results.
  if (
    !isEqual(buildingIds, selectedBuildings) &&
    !(loadingState[analytic.analyticId] === 'pending')
  ) {
    return;
  }

  dispatch(
    actions.analyticDetailsResponse({
      analyticId: analytic.analyticId,
      analyticPointAvailability,
      equipmentClassPointAvailability,
      equipmentBreakdown,
      deployedTasks,
    })
  );
};

/* Get's the point availability for a specific analytic. Rolls up the point
 * availability for the whole analytic as well as the specific equipment classes.
 */
const getAnalyticPointAvailability = async (
  api,
  _getState,
  siteId,
  buildingIds,
  analyticId,
  equipmentClassIds
) => {
  const { data } = await api.analyticsManager.getAnalyticPointAvailability(
    siteId,
    buildingIds,
    analyticId,
    equipmentClassIds
  );

  const state = _getState().analyticsManager;
  const analyticLookup = state.analyticLookup;
  const equipmentClassLookup = state.equipmentClassLookup;

  // Used to populate child equipment class tables.
  // Initialize all to empty in case there are no equipment for that equipmentClass.
  const equipmentClassPointAvailability = Object.fromEntries(
    equipmentClassIds.map(x => [
      x,
      {
        total: 0,
        fullyMapped: 0,
        notMapped: 0,
        partiallyMapped: 0,
      },
    ])
  );

  // Used to populate the task list table.
  const equipmentBreakdown = [];

  for (let equipmentClass of data.equipmentClasses) {
    // Create an object for this equipment class to track the following values.
    // total - How many pieces of equipment are there
    // fullyMapped - How many pieces of equipment have all required points
    // partiallyMapped - How many pieces of equipment have this variable.
    // notMapped - How many pieces of equipment have none of the required points.
    const tracker = {
      total: equipmentClass.equipmentPointAvailibity.length,
      fullyMapped: 0,
      notMapped: 0,
      partiallyMapped: 0,
    };

    for (let equipment of equipmentClass.equipmentPointAvailibity) {
      // Add this to our equipment breakdown.
      const equipmentSummary = {
        analyticId,
        analyticName: analyticLookup[analyticId].analyticName,
        equipmentName: equipment.equipmentName,
        equipmentId: equipment.equipmentId,
        equipmentClass: equipmentClass.equipmentClassId,
        equipmentClassName:
          equipmentClassLookup[equipmentClass.equipmentClassId],
        mappedPoints: equipment.pointAvailability
          .filter(x => x.points.length)
          .map(x => x.variable),
        missingPoints: equipment.pointAvailability
          .filter(x => x.points.length === 0)
          .map(x => x.variable),
      };

      equipmentBreakdown.push(equipmentSummary);

      // Increment the tracker based on the state of this equipments mapping.
      if (equipmentSummary.missingPoints.length === 0) {
        tracker.fullyMapped += 1;
      } else if (equipmentSummary.mappedPoints.length === 0) {
        tracker.notMapped += 1;
      } else {
        tracker.partiallyMapped += 1;
      }
    }

    // Assign the value to the dictionary.
    equipmentClassPointAvailability[equipmentClass.equipmentClassId] = tracker;
  }

  const analyticPointAvailability = Object.values(
    equipmentClassPointAvailability
  ).reduce(
    (accumulator, current) => {
      // Iterate over all of the keys (variables).
      for (let key of Object.keys(current)) {
        // Either assign the value if it doesn't exist or increment it.
        if (!(key in accumulator)) {
          accumulator[key] = current[key];
        } else {
          accumulator[key] += current[key];
        }
      }
      return accumulator;
    },
    {
      total: 0,
      fullyMapped: 0,
      notMapped: 0,
      partiallyMapped: 0,
    }
  );

  return {
    analyticPointAvailability,
    equipmentClassPointAvailability,
    equipmentBreakdown,
  };
};

/* Get's the deployed tasks for each analytic.
 */
const getDeployedTasks = async (
  api,
  _getState,
  siteId,
  buildingIds,
  analyticId
) => {
  const { data } = await api.analyticsManager.getDeployedTasks(
    siteId,
    buildingIds,
    analyticId
  );

  return data.deployedTasks;
};

export const selectBuildings = buildingIds => async (
  dispatch,
  _getState,
  { api }
) => {
  return dispatch(actions.selectBuildings(buildingIds));
};

export const selectAnalytic = (analyticId, selected) => async (
  dispatch,
  _getState,
  { api }
) => {
  const selectedAnalytics = _getState().analyticsManager.selectedAnalytics;
  selectedAnalytics[analyticId] = {
    excludedEquipment: selected
      ? new Set()
      : selectedAnalytics[analyticId].excludedEquipment,
    excludedEquipmentClasses: selected
      ? new Set()
      : selectedAnalytics[analyticId].excludedEquipmentClasses,
    selected,
  };

  return dispatch(
    actions.selectAnalytic({
      selectedAnalytics,
    })
  );
};

export const selectAllAnalytics = selected => async (
  dispatch,
  _getState,
  { api }
) => {
  const selectedAnalytics = _getState().analyticsManager.selectedAnalytics;
  for (let analyticId of Object.keys(selectedAnalytics)) {
    selectedAnalytics[analyticId] = {
      excludedEquipment: selected
        ? new Set()
        : selectedAnalytics[analyticId].excludedEquipment,
      excludedEquipmentClasses: selected
        ? new Set()
        : selectedAnalytics[analyticId].excludedEquipmentClasses,
      selected,
    };
  }

  return dispatch(
    actions.selectAllAnalytics({
      selectedAnalytics,
    })
  );
};

export const selectEquipment = (analyticId, equipmentId, selected) => async (
  dispatch,
  _getState,
  { api }
) => {
  const selectedAnalytics = _getState().analyticsManager.selectedAnalytics;
  const analytic = selectedAnalytics[analyticId];

  const equipmentBreakdown = _getState().analyticsManager.equipmentBreakdown;

  // If it is selected it is not excluded, remove.
  if (selected) {
    if (!analytic.selected) {
      analytic.selected = selected;
      const equipmentIdsForAnalytic = equipmentBreakdown
        .filter(x => x.analyticId === analyticId)
        .map(x => x.equipmentId);
      analytic.excludedEquipment = new Set(equipmentIdsForAnalytic);
    }
    analytic.excludedEquipment.delete(equipmentId);
  }
  // Otherwise add to excluded.
  else {
    analytic.excludedEquipment.add(equipmentId);
  }

  selectedAnalytics[analyticId] = {
    ...analytic,
  };

  return dispatch(
    actions.selectEquipment({
      selectedAnalytics,
    })
  );
};

export const selectEquipmentClass = (
  analyticId,
  equipmentClassId,
  selected
) => async (dispatch, _getState, { api }) => {
  const selectedAnalytics = _getState().analyticsManager.selectedAnalytics;
  const analyticDetails = _getState().analyticsManager.analyticLookup[
    analyticId
  ];
  const analytic = selectedAnalytics[analyticId];

  // If it is selected it is not excluded, remove.
  if (selected) {
    if (!analytic.selected) {
      analytic.selected = selected;
      analytic.excludedEquipmentClasses = new Set(
        analyticDetails.equipmentClasses.map(x => x.equipmentClassId)
      );
    }
    analytic.excludedEquipmentClasses.delete(equipmentClassId);
  }
  // Otherwise add to excluded.
  else {
    analytic.excludedEquipmentClasses.add(equipmentClassId);
  }

  selectedAnalytics[analyticId] = {
    ...analytic,
  };

  return dispatch(
    actions.selectEquipmentClass({
      selectedAnalytics,
    })
  );
};

export const deployAnalytic = (
  analytic,
  siteId,
  buildingIds,
  excludedEquipmentClassIds,
  excludedEquipmentIds
) => async (dispatch, _getState, { api }) => {
  try {
    dispatch(
      actions.deployAnalyticsRequest({ analyticId: analytic.analyticId })
    );

    const equipmentClassIds = new Set(
      analytic.equipmentClasses.map(x => x.equipmentClassId)
    );
    for (let equipmentClassId of excludedEquipmentClassIds) {
      equipmentClassIds.delete(equipmentClassId);
    }

    await api.analyticsManager.deployAnalytic(
      analytic.analyticId,
      analytic.analyticName,
      siteId,
      buildingIds,
      Array.from(equipmentClassIds),
      Array.from(excludedEquipmentIds)
    );

    return dispatch(
      actions.deployAnalyticsResponse({ analyticId: analytic.analyticId })
    );
  } catch (e) {
    return dispatch(
      actions.deployAnalyticsError({ analyticId: analytic.analyticId })
    );
  }
};

export const clearDeploy = () => async (dispatch, _getState, { api }) => {
  return dispatch(actions.clearDeploy());
};

export default handleActions(
  {
    [actions.standardAnalyticsRequest]: {
      next: state => ({
        ...state,
        isLoadingStandardAnalytics: true,
      }),
    },
    [actions.standardAnalyticsResponse]: {
      next: (state, { payload }) => {
        return {
          ...state,
          standardAnalytics: payload.analytics,
          equipmentClassLookup: payload.equipmentClassLookup,
          analyticLookup: payload.analyticLookup,
          isLoadingStandardAnalytics: false,
          selectedAnalytics: payload.selectedAnalytics,
        };
      },
      throw: (state, { payload }) => ({
        ...state,
        standardAnalyticsError: payload.message,
        isLoadingStandardAnalytics: false,
      }),
    },
    [actions.loadAllAnalyticDetailsRequest]: {
      next: (state, { payload }) => ({
        ...state,
        isLoadingStandardAnalyticsDetails: payload,
      }),
    },
    [actions.analyticDetailsRequest]: {
      next: (state, { payload }) => ({
        ...state,
        isLoadingStandardAnalyticsDetails: {
          ...state.isLoadingStandardAnalyticsDetails,
          [payload.analyticId]: 'loading',
        },
      }),
    },
    [actions.analyticDetailsResponse]: {
      next: (state, { payload }) => {
        const {
          analyticId,
          analyticPointAvailability,
          equipmentClassPointAvailability,
          equipmentBreakdown,
          deployedTasks,
        } = payload;
        let updatedBreakdown =
          state.equipmentBreakdown != null
            ? state.equipmentBreakdown.concat(equipmentBreakdown)
            : equipmentBreakdown;

        updatedBreakdown = alphaSortObjectArray(
          updatedBreakdown,
          'analyticName'
        );

        return {
          ...state,
          isLoadingStandardAnalyticsDetails: {
            ...state.isLoadingStandardAnalyticsDetails,
            [analyticId]: 'loaded',
          },
          // Add all of the results to the correct standard analytic.
          standardAnalytics: state.standardAnalytics.map(x => {
            return x.analyticId !== analyticId
              ? x
              : {
                  ...x,
                  deployedTasks: deployedTasks,
                  analyticPointAvailability: analyticPointAvailability,
                  equipmentClassPointAvailability: equipmentClassPointAvailability,
                };
          }),
          // Either initialize or concat.
          equipmentBreakdown: updatedBreakdown,
        };
      },
    },
    [actions.deployAnalyticsRequest]: {
      next: (state, { payload }) => {
        return {
          ...state,
          isDeployingAnalytics: {
            ...state.isDeployingAnalytics,
            [payload.analyticId]: 'deploying',
          },
        };
      },
    },
    [actions.deployAnalyticsResponse]: {
      next: (state, { payload }) => {
        return {
          ...state,
          isDeployingAnalytics: {
            ...state.isDeployingAnalytics,
            [payload.analyticId]: 'deployed',
          },
        };
      },
    },
    [actions.deployAnalyticsError]: {
      next: (state, { payload }) => {
        return {
          ...state,
          isDeployingAnalytics: {
            ...state.isDeployingAnalytics,
            [payload.analyticId]: 'failed',
          },
        };
      },
    },
    [actions.clearDeploy]: {
      next: state => {
        return {
          ...state,
          isDeployingAnalytics: {},
        };
      },
    },
    [actions.selectBuildings]: {
      next: (state, { payload }) => {
        return {
          ...state,
          selectedBuildings: [...payload],
          // Reset the analytics detail tracker to indicate we can reload the details now.
          isLoadingStandardAnalyticsDetails: {},
          equipmentBreakdown: undefined,
        };
      },
    },
    [actions.selectAnalytic]: {
      next: (state, { payload }) => {
        return {
          ...state,
          // Spread to a new object to force a re-render on selection.
          selectedAnalytics: { ...payload.selectedAnalytics },
        };
      },
    },
    [actions.selectAllAnalytics]: {
      next: (state, { payload }) => {
        return {
          ...state,
          // Spread to a new object to force a re-render on selection.
          selectedAnalytics: { ...payload.selectedAnalytics },
        };
      },
    },
    [actions.selectEquipment]: {
      next: (state, { payload }) => {
        return {
          ...state,
          // Spread to a new object to force a re-render on selection.
          selectedAnalytics: { ...payload.selectedAnalytics },
        };
      },
    },
    [actions.selectEquipmentClass]: {
      next: (state, { payload }) => {
        return {
          ...state,
          // Spread to a new object to force a re-render on selection.
          selectedAnalytics: { ...payload.selectedAnalytics },
        };
      },
    },
  },
  initialState
);
