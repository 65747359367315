// import { routerReducer as router } from "react-router-redux";
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import admin from './admin';
import analytics from './analytics';
import analyticsKeys from './analyticsKeys';
import auth from './auth';
import buildings from './buildings';
import capitalPlanning from './capitalPlanning';
import csv from './csv';
import filters from './filters';
import kpis from './kpis';
import livemap from './liveMap';
import localization from './localization';
import navigation from './navigation';
import opportunities from './opportunities';
import powerBiEmbedded from './powerBiEmbedded';
import prevMaintenance from './prevMaintenance';
import renewables from './renewables';
import resources from './resources';
import savings from './savings';
import tour from './tour';
import ui from './ui';
import utility from './utility';
import budgetPlanningScenarios from './budgetPlanningScenarios';
import alert from './alert';
import floorplan from './floorplan';
//-- Internal Tools --
import analyticsDeployer from './analyticsDeployer';
import analyticsManager from './analyticsManager';
import mckGeneral from './mckGeneral';
import chartBuilder from './chartBuilder';
import scheduler from './scheduler';
import configuration from './configuration';
import portfolioManager from './portfolioManager';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    ...admin,
    analytics,
    analyticsKeys,
    auth,
    budgetPlanningScenarios,
    buildings,
    capitalPlanning,
    csv,
    filters,
    floorplan,
    kpis,
    livemap,
    localization,
    navigation,
    opportunities,
    powerBiEmbedded,
    prevMaintenance,
    renewables,
    resources,
    savings,
    scheduler,
    tour,
    ui,
    utility,
    alert,

    //-- Internal Tools --
    analyticsDeployer,
    analyticsManager,
    mckGeneral,
    chartBuilder,
    configuration,
    portfolioManager,
  });

export default rootReducer;
