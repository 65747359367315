const getQueryStringList = (input, fieldName) => {
  let qString = '';
  for (let i = 0; i < input.length; i++) {
    qString += `&${fieldName}=${input[i]}`;
  }
  return qString;
};

export class AnalyticsManagerService {
  constructor(api) {
    this.api = api;
  }

  async getStandardAnalytics(siteId, buildingIds) {
    let url = `/analytics?siteId=${siteId}`;
    url += getQueryStringList(buildingIds, 'buildingIds');
    return this.api.get(url);
  }

  async getAnalyticPointAvailability(
    siteId,
    buildingIds,
    analyticId,
    equipmentClassIds
  ) {
    let url = `/analytics/${analyticId}/point-availability?siteId=${siteId}`;
    url += getQueryStringList(equipmentClassIds, 'equipmentClassIds');
    url += getQueryStringList(buildingIds, 'buildingIds');
    return this.api.get(url);
  }

  async getDeployedTasks(siteId, buildingIds, analyticId) {
    let url = `/analytics/${analyticId}/tasks?siteId=${siteId}`;
    url += getQueryStringList(buildingIds, 'buildingIds');
    return this.api.get(url);
  }

  async deployAnalytic(
    analyticId,
    analyticName,
    siteId,
    buildingIds,
    equipClassIds,
    excludedEquipmentIds
  ) {
    let url = `/analytics/library/scope`;
    return this.api.pyPost(
      url,
      {
        libraryid: analyticId,
        displayName: analyticName,
        siteid: siteId,
        buildingIds,
        equipClassIds,
        equipExcludeIds: excludedEquipmentIds,
      },
      undefined,
      true
    );
  }
}
